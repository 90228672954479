import {
    Component
} from "main/Imports";

import {Row, Col} from "react-bootstrap";

class WhitePaper extends Component {

    getText = () => {
        return {
            default: [
                {
                    title: this.t('Что такое криптовалюта?'),
                    desc: this.t('Криптовалюта — разновидность цифровой валюты, работающая в полностью автоматическом режиме. Криптоденьги не привязаны ни к одной существующей валюте, ни к цене на нефть, ни к любым другим активам. Криптовалюта существует только в цифровом пространстве и хранится в электронном кошельке.'),
                },
                {
                    title: this.t('Что такое бонусная система?'),
                    desc: this.t('Начисление бонусных баллов за совершенные покупки.'),
                },
                {
                    title: this.t('Что такое KYC?'),
                    desc: this.t('«Знай своего клиента» (Know Your Customer). Стандартная процедура верификации личности для финансовых институтов, бирж и букмекерских контор.'),
                },
            ],
            wr: [
                {
                    title: this.t('Что такое WR Token'),
                    desc: this.t('WR Token – это платежное средство на базе токена Ripple. Это означает, что мы не создаем свой блокчейн самостоятельно, <br/> но используем все основные функции уже созданного токена. '),
                },
                {
                    title: this.t('Как купить WR Token'),
                    desc: this.t('Уже сейчас вы можете приобрести токен <br/> на бирже {whitebit_com}. Обратите внимание, чтобы приобрести токен за обычные деньги, вам необходимо верифицировать свою личность по процедуре KYC. Для этого после регистрации выберите в главном меню пункт «Пройти KYC».', {
                        whitebit_com: this.ready(() => {
                            return this.__ROUTE.link('Whitebit.com', 'https://whitebit.com', {
                                className: 'color-pink',
                                target: '_blank'
                            })
                        })
                    }),
                },
            ]
        }
    }


    getWrQ = () => {
        let items = []
        let text = this.getText()

        text.wr.map((v, k) => {
            return items.push((
                <Col xs={12} lg={6} key={this.uid()}>
                    <h3 className={'title title-' + k}>
                        {v.title}
                    </h3>
                    <Row>
                        <Col xs={12} lg={{span: 10, offset: 1}} xl={{span: 8, offset: 2}} className={'light fsXs desc'}>
                            {v.desc}
                        </Col>
                    </Row>
                </Col>
            ))
        })

        return <Row>
            {items}
        </Row>
    }

    getDefault = () => {
        let items = []
        let text = this.getText()

        text.default.map((v, k) => {
            return items.push((
                <div key={this.uid()}>
                    <h3 className={'color-blue'}>
                        {v.title}
                    </h3>
                    <p className={'light fsXs desc'}>
                        {v.desc}
                    </p>
                </div>
            ))
        })

        return items
    }

    render() {
        let c = (
            <div className={'white-paper'}>
                {/*<h1 className={'header tac'}>*/}
                {/*    {this.t('White Paper')}*/}
                {/*</h1>*/}
                {/*<p className={'tac light fsMd ttuc '}>*/}
                {/*    {this.t('Все еще остались вопросы?<br/>Скачайте наш White paper!')}*/}
                {/*    <br/>*/}
                {/*</p>*/}
                {/*<p className={'tac fsXs ttuc'}>*/}
                {/*    {this.__ROUTE.link(this.t('Скачайте наш White paper!'), '#', {*/}
                {/*        className: 'tdu color-pink download-link'*/}
                {/*    })}*/}
                {/*</p>*/}
                {/*<br/>*/}
                <h1 className={'header tal fsLg pr'}>
                    <div className="bubble_lg_right red3"></div>
                    {this.t('Часто задаваемые <br/> вопросы')}
                </h1>

                <br/>
                <div className={'default'}>
                    {this.getDefault()}
                </div>
                <br/>
                <br/>
                <div className={'wr-token'}>
                    <h3 className={'fsLg bold tac under-text'}>
                        WR Token
                        <span className="left"></span>
                        <span className="right"></span>
                    </h3>
                    {this.getWrQ()}
                </div>


            </div>
        )

        return this.ready(() => c)
    }

}

export default WhitePaper;