import {
    Component
} from "main/Imports";

import Top from "main/views/home/Top";
import Advantages from "main/views/home/Advantages";
import Market from "main/views/home/Market";
import Info from "main/views/home/Info";
import Plans from "main/views/home/Plans";
import WhitePaper from "main/views/home/WhitePaper";
// import HowToUse from "main/views/home/HowToUse";
import Promo from "main/views/home/Promo";

class Home extends Component {

    render() {
        let c = (
            <>
                <Top/>
                <div id={this.__ROUTE.urls.advantages.replace('/', '')}>
                    <Advantages/>
                </div>

                <div id={this.__ROUTE.urls.audience.replace('/', '')}>
                    <Market/>
                </div>

                <Info/>

                <div id={this.__ROUTE.urls.devPlan.replace('/', '')}>
                    <Plans/>
                </div>

                <WhitePaper/>
                {/*<HowToUse/>*/}

                <div id={this.__ROUTE.urls.contacts.replace('/', '')}>
                    <Promo/>
                </div>
            </>
        )

        return c
    }

}

export default Home;