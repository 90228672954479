import {
    Component
} from "main/Imports";

class Top extends Component {

    render() {
        let url = this.ready(() => {
            return this.__APP.data.language === 'en' ? 'https://vertera.eu/en/geo-lang' : 'https://vertera.org/'
        })

        return (
            <>
                <br/>
                <h1 className={'header top-header'}>
                    <span className={'blue'}>
                        WR Token-
                    </span>
                    {this.t('бонусная система <br/> на базе блокчейн')}
                </h1>
                <br/>
                <br/>
                <p className={'fsMd light'}>
                    {this.t('Попробуйте бонусную систему в тестовом режиме <br/>' +
                        ' на сайте {vertera_link}', {
                        vertera_link: this.__ROUTE.link('Vertera',
                            url ? url : '#'
                            , {
                                target: '_blank',
                                className: 'color-pink',
                            }),
                    })}
                </p>
            </>
        )
    }

}

export default Top;