import {
    Component
} from "main/Imports";

import "main/styles/style.scss"

import MainLayout from "main/layout/MainLayout"

class MainApp extends Component {
    render = () => <MainLayout/>
}

export default MainApp;
