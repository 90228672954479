import {Component} from "main/Imports";
import {Container} from "react-bootstrap";

import MainContent from "main/layout/MainContent";
import MainMenu from "main/layout/MainMenu";

class MainLayout extends Component {

    data = {
        theme: this.get('theme', 'bp4-dark'),
    }

    /**
     * toggle system theme
     */
    toggleTheme = () => this.set({theme: (this.data.theme === 'bp4-dark' ? 'bp4-light' : 'bp4-dark')}, true)

    /**
     * @return {JSX.Element}
     */
    render() {

        let c = (
            <>
                <div className={'app-container ' + this.data.theme}>
                    <Container>
                        <div className={'top-side'}>
                            <div className={'bg_logo'}></div>
                        </div>
                        <MainMenu/>
                        <MainContent/>
                    </Container>
                </div>
            </>
        )

        return this.__HOT_KEYS.render('Shift+X', c, () => {
            return this.toggleLanguage();
        })


    }
}


export default MainLayout;