let TranslationEN = {
    "Почему WR Token":
        "Why WR Token?",



    "Аудитория":
        "Audience",



    "План развития":
        "Development Plan",



    "Контакты":
        "Contacts",



    "Купить токен":
        "Buy Token",



    "бонусная система <br/> на базе блокчейн":
        "bonus system <br/> based on blockchain",



    "по ссылке":
        "at the link ",



    "Попробуйте бонусную систему в тестовом режиме <br/> на сайте {vertera_link}":
        "Try the bonus system in test mode at {vertera_link}",



    "Преимущества бонусной <br/> системы на базе блокчейн":
        "Advantages of the bonus <br/> system based on blockchain",



    "Универсальность":
        "Versatility",



    "Больше нет привязки бонусов к конкретному магазину, покупайте в любых магазинах сети.":
        "No more binding of bonuses to a certain store, make purchases at any store of the network.",



    "Пассивный доход":
        "Passive Income",



    "Получайте доход за счет роста токена с увеличением базы магазинов и клиентов.":
        "Gain income on the account of the growth of the token with the growth of the stores’ base and of the customers’ base.",



    "Быстрая конвертация":
        "Quick Conversion",



    "Конвертируй бонусы в реальные деньги и обратно.":
        "Convert your bonuses into real money and vice-versa.",



    "Выгода":
        "Benefit",



    "Покупайте токены и используйте их в качестве бонусов по повышенной цене.":
        "Buy tokens and use them as bonuses at increased price.",



    "Безопасность для всех":
        "Secure for Everyone",



    "Безопасность транзакций в сети как для покупателей, так и для продавцов.":
        "Security of transactions within the network for both buyers and sellers.",



    "Блокчейн инновации":
        "Blockchain Innovations",



    "Используй все преимущетва децентрализованной системы оплат на базе Ripple. Низкие комиссии в сети, быстрая скорость обработки, прозрачность всех переводов.":
        "Use all advantages of the decentralized payment system based on Ripple. Low commissions within the network, quick processing, transparency of all transfers.",



    "Аудитория <br/> и объемы рынка":
        "Audience <br/> and Market Volume",



    "Целевые группы, с которыми <br/> мы сейчас ведем работу":
        "Target Groups With Which <br/> We Currently Work ",



    "Аудитория Vertera –<br/>400 000 человек":
        "Vertera’s Audience is<br/>400,000 People",



    "Тестовые группы уже сейчас работают с Alpha-версией<br/>нашего решения, чтобы вскоре открыть возможность использовать токен в качестве бонуса всем клиентам {vertera_link}.":
        "Test groups now already work with Alpha version<br/>of our solution in order to open, in the nearest future, the possibility to use our token as bonus by all {vertera_link} customers.",



    "Мелкие и средние <br/>ритейлеры":
        "Small and Medium <br/>Retailers",



    "Разрабатываем для них готовые бонусные программы на базе нашего токена, которые включают программное решение, юридическую базу и онлайн сопровождение. Подключение одного ритейлера будет означать подключение всей базы его покупателей к работе <br/> с WR Token.":
        "For them, we develop ready-made bonus programs based on our token which include a software solution, legal framework, and online support. Joining us by one retailer will mean that of all of his customer base will be connected for working <br/> with WR Token.",



    "Пользователи <br/> биржи Whitebit":
        "Users <br/> of Whitebit Exchange",



    "Один из крупнейших crypto <br/> market проектов на территории <br/> СНГ с русскоговорящей <br/> аудиторией, которая расположена к работе <br/> со «своими» проектами. На нем мы уже провели листинг, чтобы дать возможность нашим пользователям быстро покупать и обменивать WR Token в сети.":
        "One of the largest crypto <br/> market projects in the territory <br/> of the CIS with Russian-speaking <br/> audience who prefer working <br/> with ‘insider’ projects. We have already arranged the listing there so that our users can quickly buy and exchange WR Token in the network.",



    "WR Token <br/> в цифрах":
        "WR Token <br/> in Numbers ",


    "Текущий курс":
        "Current Exchange Rate",



    "0.33$ 3434343":
        "0.33$ 3434343",



    "Объем эмиссии":
        "Issue volume",



    "Выпущено: {val}":
        "Emitted: {val}",



    "Максимум: {val}":
        "Maximum: {val}",



    "Количество владельцев":
        "Number of Owners",



    "Реализация бонусной <br/> программы Vertera":
        "Implementation of the Bonus <br/> Program of Vertera",



    "Улучшение работы бонусной <br/> программы Vertera Stage 1":
        "Improvement of the Bonus <br/> Program of Vertera Stage 1",



    "Улучшение работы бонусной <br/> программы Vertera Stage 2":
        "Improvement of the Bonus <br/> Program of Vertera Stage 2",



    "Создание блокчейна WRT <br/> на базе Ripple":
        "Development of the WRT <br/> blockchain based on Ripple",



    "Разработка реферальной <br/> программы":
        " Development of the Referral <br/> Program",



    "Интеграция <br/> с WR market Stage 2":
        "Integration <br/> with WR market Stage 2",



    "Интеграция <br/> с WR market Stage 1":
        "Integration <br/> with WR market Stage 1",



    "Smart Stacking":
        "Smart Stacking",



    "Программа <br/> лояльности":
        "Loyalty <br/> Program",



    "Airdrop":
        "Airdrop",



    "Готовое решение <br/> для ритейлеров":
        "Ready-Made Solution <br/> for Retailers",



    "Добавление функционала <br/> аналитики Stage 1":
        "Adding the Analytics <br/> Functionality Stage 1",



    "Разработка плагина <br/> для браузера":
        "Development of Browser <br/> Plugin",



    "Создание <br/> криптокошелька":
        "Creation of <br/> Crypto Wallet",



    "Добавление функционала <br/> аналитики Stage 2":
        "Adding the Analytics <br/> Functionality Stage 2",



    "Наши планы <br/> по развитию проекта":
        "Our Plans for <br/> Development of the Project",



    "{vertera} разрабатывает единственную универсальную <br/> систему бонусных баллов на базе Blockchain.":
        "{vertera} develops a unique versatile <br/> system of bonus points based on Blockchain.",



    "WRToken <br/> Product Roadmap":
        "WRToken <br/> Product Roadmap",



    "White Paper":
        "White Paper",



    "Все еще остались вопросы?<br/>Скачайте наш White paper!":
        "Still have questions?<br/>Download our White Paper!",



    "Скачайте наш White paper!":
        "Download our White Paper!",



    "Часто задаваемые <br/> вопросы":
        "Frequently Asked <br/> Questions",



    "Что такое криптовалюта?":
        "What is Cryptocurrency?",



    "Криптовалюта — разновидность цифровой валюты, работающая в полностью автоматическом режиме. Криптоденьги не привязаны ни к одной существующей валюте, ни к цене на нефть, ни к любым другим активам. Криптовалюта существует только в цифровом пространстве и хранится в электронном кошельке.":
        "Cryptocurrency is a kind of digital currency operating in fully automated mode. Crypto money is linked neither to any of the existing currencies, nor to oil prices or any other assets. Cryptocurrency only exists in the digital space and is kept in electronic wallet.",



    "Что такое бонусная система?":
        "What is the bonus system?",



    "Начисление бонусных баллов за совершенные покупки.":
        "Receiving bonus points for purchase made.",



    "Что такое KYC?":
        "What is KYC?",



    "«Знай своего клиента» (Know Your Customer). Стандартная процедура верификации личности для финансовых институтов, бирж и букмекерских контор.":
        "”Know Your Customer”. A standard procedure of identity verification used by financial institutions, exchanges, and betting firms.",



    "Что такое WR Token":
        "What is WR Token",



    "WR Token – это платежное средство на базе токена Ripple. Это означает, что мы не создаем свой блокчейн самостоятельно, <br/> но используем все основные функции уже созданного токена. ":
        "WR Token is a payment instrument based on Ripple token. That means that we don’t create our blockchain from grassroots, <br/> but use all main functions of already existingtoken. ",



    "Как купить WR Token":
        "How can I buy WR Token",



    "Уже сейчас вы можете приобрести токен <br/> на бирже {whitebit_com}. Обратите внимание, чтобы приобрести токен за обычные деньги, вам необходимо верифицировать свою личность по процедуре KYC. Для этого после регистрации выберите в главном меню пункт «Пройти KYC».":
        "Yet today, you can by the token <br/> at {whitebit_com} exchange. Please note that  in order to buy a token for regular money, you have to verify your identity using the KYC procedure. To do that, select the “Pass KYC” item in the main menu.",



    "Как пользоваться <br/> WR Token:":
        "How to use <br/> WR Token:",



    "применение в магазинах <br/> партнерской сети":
        "usage in stores <br/> of partners’ network ",



    "Продвигай WRT":
        "Promote WRT",



    "получай вознаграждение!":
        "earn remuneration!",



    "Мы всегда рады сотрудничать с лидерами мнений.":
        "We are always glad to collaborate with opinion leaders.",



    "Вы":
        "You",



    "владелец аккаунтов в instagram, vk, facebook или других соцсетях с лояльной <br/> и масштабной аудиторией? И готовы продвигать наш токен в своих сетях? ":
        "are the owner of instagram, facebook, vk accounts or of accounts in other social media with a loyal <br/> and large audience? And are ready to promote our token in your media? ",



    "Напишите нам!":
        "Contact us!",



    "Мы вместе найдем лучшие <br/> варианты для сотрудничества.":
        "Together, we will find best ways <br/> of cooperation.",


    "показать все":
        "show all",



    "Письменный отказ <br/> от ответственности":
        "Disclaimer",



    "Политика <br/> конфиденциальности":
        "Privacy policy",



}

export default TranslationEN
