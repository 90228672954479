import {
    Component
} from "main/Imports";

import {Row, Col} from "react-bootstrap";

class Promo extends Component {

    /**
     * get social net links
     * @return {JSX.Element}
     */
    getSocial = () => {

        let params = {
            className: 'tac',
            xs: {
                span: 12
            }
        }

        let urls = {
            f: '',
            wk: '',
            in: '',
            od: '',
        }

        return (
            <Row>
                <Col {...params}>
                    {this.__ROUTE.link('', urls.f, {
                        className: 'social-link f'
                    })}
                    {this.__ROUTE.link('', urls.wk, {
                        className: 'social-link wk'
                    })}
                    {this.__ROUTE.link('', urls.in, {
                        className: 'social-link in'
                    })}

                    {this.__ROUTE.link('', urls.od, {
                        className: 'social-link od'
                    })}
                </Col>
            </Row>
        )
    }

    /**
     * get steps
     * @return {JSX.Element}
     */
    getSteps = () => {
        return (
            <>
                <br/>

                <Row>
                    <Col lg={{span: 2, offset: 1}}>
                        <h3 className={'fsLg ttuc'}>
                            {this.t('Вы')} -
                        </h3>
                    </Col>
                    <Col>
                        <div className={'fsXs light text-1'}>
                            {this.t('владелец аккаунтов в instagram, vk, facebook или других соцсетях с лояльной <br/> и масштабной аудиторией? И готовы продвигать наш токен в своих сетях? ')}
                        </div>
                    </Col>
                </Row>

                <br/>

                <Row className={'promo-last-block'}>
                    <Col lg={{span: 5, offset: 1}}>
                        <h3 className={'fsLg'}>
                            {this.t('Напишите нам!')}
                        </h3>
                    </Col>
                    <Col>
                        <div className={'fsXs light text-2'}>
                            {this.t('Мы вместе найдем лучшие <br/> варианты для сотрудничества.')}
                        </div>
                    </Col>
                </Row>
                <div className="separator"></div>
            </>
        )
    }

    render() {
        let c = (
            <div className={'how-to-use'}>

                <Row>
                    <Col>
                        <h1 className={'header pr'}>
                            <div className="bubble_lg_left red"></div>
                            <div className={'light ttuc'}>
                                {this.t('Продвигай WRT')} -
                            </div>
                            <div className={'color-blue normal ttuc'}>
                                {this.t('получай вознаграждение!')}
                            </div>
                        </h1>
                        <br/>
                        <p className={'bold fsSm'}>
                            {this.t('Мы всегда рады сотрудничать с лидерами мнений.')}
                        </p>
                    </Col>
                </Row>
                {/*{this.getSteps()}*/}
                {/*{this.getSocial()}*/}
                {this.getContacts()}

            </div>
        )

        return this.ready(() => c)
    }


    getContacts = () => {


        return (
            <div className={'contacts tac'}>

                {this.ready(() => {
                    return (
                        <>
                            <br/>
                            <Row>
                                <Col xs={{span: 12}} md={{span:6}} className={'tac'}>
                                    {this.__ROUTE.link(this.t('Письменный отказ <br/> от ответственности'),
                                        '/doc/WORLD OF RETAIL AML POLICY_' + (this.__APP.data.language === 'ru' ? 'Rus' : 'Eng') + '.pdf', {
                                            className: 'color-pink fsSm light',
                                            target: '_blank',
                                        })}
                                    <br/>
                                    <br/>
                                </Col>

                                <Col xs={{span: 12}} md={{span:6}} className={'tac'}>
                                    {this.__ROUTE.link(this.t('Политика <br/> конфиденциальности'),
                                        '/doc/WORLD OF RETAIL PRIVACY POLICY_' + (this.__APP.data.language === 'ru' ? 'Rus' : 'Eng') + '.pdf', {
                                            className: 'color-pink fsSm light',
                                            target: '_blank',
                                        })}
                                </Col>
                            </Row>
                        </>

                    )
                })}

                <br/>
                <br/>
                <div>
                    World of Retail OÜ
                </div>

                <div>
                    reg. nr 14588037
                </div>
                <div>
                    {/*Narva mnt. 13, Tallinn 10151, Estonia.*/}
                    Parda 3, Tallinn 10151, Estonia
                </div>
                <div>
                    +41 41 553 87 41
                </div>
                <div>
                    {/*<a href="mailto:wr.estonia@gmail.com" className={'color-pink'}>wr.estonia@gmail.com</a>*/}
                    <a href="mailto:info@wrcoin.io" className={'color-pink'}>info@wrcoin.io</a>
                </div>
                <br/>
                <br/>
            </div>
        )

    }
}

export default Promo;