import {
    Component
} from "main/Imports";

import {Row, Col} from "react-bootstrap";

class Advantages extends Component {

    getText = () => {
        return {
            1: {
                title: this.t('Универсальность'),
                desc: this.t('Больше нет привязки бонусов к конкретному магазину, покупайте в любых магазинах сети.'),
            },
            2: {
                title: this.t('Пассивный доход'),
                desc: this.t('Получайте доход за счет роста токена с увеличением базы магазинов и клиентов.'),
            },
            3: {
                title: this.t('Быстрая конвертация'),
                desc: this.t('Конвертируй бонусы в реальные деньги и обратно.'),
            },
            4: {
                title: this.t('Выгода'),
                desc: this.t('Покупайте токены и используйте их в качестве бонусов по повышенной цене.'),
            },
            5: {
                title: this.t('Безопасность для всех'),
                desc: this.t('Безопасность транзакций в сети как для покупателей, так и для продавцов.'),
            },
            6: {
                title: this.t('Блокчейн инновации'),
                desc: this.t('Используй все преимущетва децентрализованной системы оплат на базе Ripple. Низкие комиссии в сети, быстрая скорость обработки, прозрачность всех переводов.'),
            },
        }
    }


    getItemTemplate = (item, i) => {
        let bg = <div className="bubble_lg_right"></div>
        let bg1 = <div className="bubble_lg_left blue"></div>
        if (i !== '6')
            bg = ''
        if (i !== '1')
            bg1 = ''

        return (
            <div key={this.uid()} className={'item item-' + i + (i % 2 === 0 ? ' right' : ' left')}>
                <h2 className={'fsMd'}>
                    {item.title}
                </h2>
                <div className={'light fsSm'}>
                    {bg}
                    {bg1}
                    {item.desc}
                </div>
            </div>
        )
    }

    getItems = () => {
        let l = []
        let r = []
        let text = this.getText();
        Object.keys(text).map((k, v) => {
                let c = this.getItemTemplate(text[k], k)
                return k % 2 === 0 ? r.push(c) : l.push(c)
            }
        )

        return (
            <Row>
                <Col xs={12} md={6} className={'left-container'}>
                    {l}
                </Col>
                <Col xs={12} md={6} className={'right-container'}>
                    {r}
                </Col>
            </Row>
        )
    }

    render() {
        let c = (
            <div className={'advantages'}>
                <br/>
                <h1 className={'header tac'}>
                    {this.t('Преимущества бонусной <br/> системы на базе блокчейн')}
                </h1>
                <Row className={'items'}>
                    {this.getItems()}
                </Row>
            </div>
        )

        return this.ready(() => c)
    }

}

export default Advantages;