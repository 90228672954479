import {Component} from "main/Imports";
import {Row, Col} from "react-bootstrap";

class MainMenu extends Component {

    data = {
        langSelectOpen: false,
    }

    /**
     * change langSelectOpen value
     * @param val
     */
    setLangSelectOpen = (val) => {
        this.set({
            langSelectOpen: val
        })
    }

    /**
     * @return {JSX.Element}
     */
    render() {

        let langName = this.ready((app) => app.data.languageList[app.data.language].label);

        let c = (
            <Row className={'main-menu'}>
                <Col xs={12} className={'align-center_'}>
                    <Row>
                        <Col className={'tal'} xs={4} lg={2}>
                            {this.__ROUTE.link(<img alt={'logo'} src={'/img/logo.png'} className={'logo'}/>, '/')}
                        </Col>

                        <Col className={'tar d-none d-lg-block'} lg={7}>
                            {this.getItems()}
                            <div style={{height: '30px', display: 'inline-block',}}></div>
                        </Col>

                        <Col className={'tar'} xs={8} lg={3}>
                            {
                                this.__ROUTE.link((
                                    <span>
                                        {langName}
                                        <span className={'caret ' + (this.data.langSelectOpen ? 'open' : '')}>
                                            ^
                                        </span>
                                        <span className={'lang-list ' + (this.data.langSelectOpen ? 'open' : '')}>
                                            {
                                                this.ready(() => {
                                                    return Object.entries(this.__APP.data.languageList).map((langEntry) => {
                                                        return this.__APP.data.language !== langEntry[0] ? (
                                                            <span key={this.uid()} className={'lang-item'}
                                                                  onClick={() => this.toggleLanguage()}>
                                                                {langEntry[1].label}
                                                            </span>
                                                        ) : ''
                                                    })
                                                })
                                            }
                                        </span>
                                    </span>
                                ), '#', {
                                    className: 'switch-language-bt',
                                    onClick: (e) => {
                                        this.setLangSelectOpen(!this.data.langSelectOpen)
                                        return e.preventDefault()
                                    }
                                })
                            }
                            {
                                this.__ROUTE.link(this.t('Купить токен'), 'https://whitebit.com/', {
                                    className: 'buy-token',
                                    target: '_blank'
                                })
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )

        return c
    }

    /**
     * init menu items
     */
    getItems = () => {

        let items = []

        let route = this.__ROUTE

        let links = [
            {
                label: this.t('Почему WR Token'),
                url: route.urls.advantages,
            },
            {
                label: this.t('Аудитория'),
                url: route.urls.audience,
            },
            {
                label: this.t('План развития'),
                url: route.urls.devPlan,
            },
            {
                label: this.t('Контакты'),
                url: route.urls.contacts,
            },
        ]

        links.map((k, i) => links[i].visible === false ? null : items.push(this.itemEl(links, i)))

        return items
    }

    /**
     * @param ar
     * @param i
     * @returns {JSX.Element}
     */
    itemEl = (ar, i) => {
        let c = this.__ROUTE.link(
            ar[i].label,
            ar[i].url,
            {
                className: 'main_menu_link',
                key: this.uid('main_menu_link'),
                btnProps: {
                    className: 'cp',
                }
            }
        )

        return c
    }
}

export default MainMenu;