import {HotkeysTarget} from "@blueprintjs/core";

const HotKeys = class HotKeys {


    /**
     * render content on key combo ex: Shift+M
     * @param combo
     * @param content
     * @param callback
     * @param params
     *
     * @return {JSX.Element}
     */
    render = (combo, content, callback, params = {}, allowInInput = false) => {
        let hotkeys = [
            {
                combo: combo,
                allowInInput: (allowInInput === true || combo.split('+').length > 2),
                global: true,
                label: combo,
                onKeyDown: () => (callback && typeof callback === 'function' ? callback() : ''),
            }
        ]
        return (
            <HotkeysTarget hotkeys={hotkeys} {...params}>
                {content}
            </HotkeysTarget>
        )
    }
}

export default new HotKeys()