import TranslationEN from "main/translates/en";

let Translations = {
    getTranslationsByLang: (lang) => {
        let data = {
            en: TranslationEN,
        }
        return data[lang] ? data[lang] : {}
    }
}

export default Translations