import React from "react";
import CoreContext from "core/CoreContext";
import Route from "core/Route";
import Content from "core/Content";
import HotKeys from "core/HotKeys";
import Translations from "main/translates/Translations";
import * as ReactDOMServer from "react-dom/server";
import {BrowserRouter, Router} from "react-router-dom";

class BaseComponent extends React.Component {

    data = {}

    static contextType = CoreContext;

    /**
     * @type {Route}
     */
    __ROUTE = Route

    /**
     * @type {Content}
     */
    __CONTENT = Content

    /**
     * @type {IndexClass}
     */
    __APP = this.context;

    /**
     * @type {HotKeys}
     */
    __HOT_KEYS = HotKeys;

    /**
     * @inheritDoc
     */
    constructor(props) {
        super(props);
        this.state = this.data
    }

    /**
     * @inheritDoc
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.data = this.state
    }

    /**
     * @inheritDoc
     */
    componentDidMount() {
        this.__APP = this.context
        this.set(this.state)
    }

    /**
     * set state method
     * @param kv {{theme: (string)}}
     * @param remember boolean
     */
    set = (kv = {}, remember = false) => {
        this.data = Object.assign(this.data, kv)
        if (remember === true)
            Object.keys(kv).map((k) => localStorage.setItem(k, kv[k]))

        this.setState(this.data)
        if (typeof remember === 'function')
            remember()
    }

    /**
     * get param
     * @param key
     * @param alternative
     * @return {string|null}
     */
    get = (key, alternative = null) => {
        let v = localStorage.getItem(key)
        if (v)
            return v
        return alternative
    }

    /**
     * change system language
     * @param lang
     */
    changeLanguage = (lang) => this.__APP.set({language: lang})

    /**
     * toggle system language
     */
    toggleLanguage = () => this.__APP.set({language: (this.__APP.data.language === 'ru' ? 'en' : 'ru')}, true)

    /**
     * translate message
     * @param m
     * @param params
     * @return {*}
     */
    t = (m, params = {}) => {

        // if (!testt1[m]){
        //     testt = testt+'"'+m+'":"'+m+'",'
        //     testt1[m] = m
        //     console.log(testt)
        // }


        if (!this.__APP)
            return 'NO_TRANSLATION!!!!!!!!!!!!!!!!!!!!!!!!!!!';





        let t = Translations.getTranslationsByLang(this.__APP.data.language);
        let esc = m.replace('\n', '').replace('  ', '')

        m = t[esc] ? t[esc] : m

        m = this.replaceParams(m, params)

        let brs = m.split('\n');

        if (brs.length > 1) {
            m = []
            brs.map((b) => {
                return m.push(
                    <span key={this.uid()} style={{display: 'block'}}>
                        <span dangerouslySetInnerHTML={{__html: this.replaceParams(b, params)}}/>
                    </span>
                )
            })
            return m
        }

        return <span dangerouslySetInnerHTML={{__html: m}}/>
    }

    replaceParams = (string, params) => {
        let ret = string;

        Object.keys(params).map((k) => {
            let v = params[k]
            if (typeof v === 'object')
                v = ReactDOMServer.renderToStaticMarkup(
                    <BrowserRouter {...this.__APP.data.routerParams}>
                        <Router history={this.__ROUTE.history}>{v}</Router>
                    </BrowserRouter>
                )
            return ret = ret.replaceAll('{' + k + '}', v)
        })
        return ret
    }

    /**
     * generate unique ID
     * @param prefix
     * @returns {string}
     */
    uid = (prefix = '') => prefix + (Math.random().toString(16).slice(2))

    /**
     *
     * @param callback
     * @return {IndexClass|null}
     */
    ready = (callback) => {
        if (this.__APP && typeof callback === 'function') {
            return callback(this.__APP)
        }
        return null
    }
}

export default BaseComponent;