import {
    Component
} from "main/Imports";

import {Row, Col} from "react-bootstrap";

class Info extends Component {

    render() {
        return (
            <div className={'info pr'}>
                <br/>
                <div className="bg_logo_gray"></div>
                <h1 className={'header'}>
                    {this.t('WR Token <br/> в цифрах')}
                </h1>
                <br/>
                <Row>
                    {/*<Col lg={3} xs={12} className={'item-1'}>*/}
                    {/*    <Row className={'d-flex align-items-center'}>*/}
                    {/*        <Col lg={12} xs={6} className={'light fsMd d-flex '}>*/}
                    {/*            {this.t('Текущий курс')}*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={12} xs={6} className={'neon'}>*/}
                    {/*            {this.t('0.33$ 3434343')}*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Col>*/}

                    <Col lg={12} xs={12} className={'item-2'}>
                        <Row className={'d-flex align-items-center'}>
                            <Col lg={12} xs={6} className={'light fsMd'}>
                                {this.t('Объем эмиссии')}:
                            </Col>
                            <Col lg={12} xs={6} className={'align-center'}>
                                <span className={'neon'}>
                                    {/*{this.t('Выпущено: {val}', {val: '10 000 000 000'})}  WRT*/}
                                    10 000 000 000 WRT
                                </span>

                                {/*<span className={'fsMd'}>*/}
                                {/*    {this.t('Максимум: {val}', {val: '34343'})}*/}
                                {/*</span>*/}
                            </Col>
                        </Row>
                    </Col>


                    {/*<Col lg={3} xs={12} className={'item-3'}>*/}
                    {/*    <Row className={'d-flex align-items-center'}>*/}
                    {/*        <Col lg={12} xs={6} className={'light fsMd'}>*/}
                    {/*            /!*{this.t('Количество владельцев')}*!/*/}
                    {/*            &nbsp;*/}
                    {/*        </Col>*/}
                    {/*        <Col lg={12} xs={6} className={'neon'}>*/}
                    {/*            WRT*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Col>*/}
                </Row>

                {/*<div className={'tac'}>*/}
                {/*    {this.__ROUTE.link(this.t('Купить токен'), '#', {*/}
                {/*        className: 'buy-token'*/}
                {/*    })}*/}
                {/*</div>*/}
                <br/>
            </div>
        )
    }

}

export default Info;