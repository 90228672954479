import {
    Component
} from "main/Imports";

import {Row, Col} from "react-bootstrap";

class Plans extends Component {

    data = {
        open: false
    }

    /**
     * @return {[{title: *}, {title: *}, {title: *}, {title: *}, {title: *}, null, null, null, null, null, null, null, null, null, null]}
     */
    getItems = () => {
        return {
            1:
                [
                    {
                        title: this.ready(() => {
                            return this.__ROUTE.link(this.t('Создание блокчейна WRT <br/> на базе Ripple'),
                                'https://github.com/World-of-Retail-Token/wrtd', {
                                    className: 'color-pink cp tal',
                                    target: '_blank',
                                    style: {position: 'relative',}
                                })
                        })

                    },
                    {
                        title: this.ready(() => {
                            return this.__ROUTE.link(this.t('Создание <br/> криптокошелька'), 'https://wallet.wr.company/', {
                                className: 'color-pink cp',
                                target: '_blank',
                                style: {position: 'relative',}
                            })
                        })
                    },
                    {
                        title: this.t('Реализация бонусной <br/> программы Vertera')
                    },

                ],
            2:
                [
                    {
                        title: this.t('Разработка реферальной <br/> программы')
                    },
                    {
                        title: this.t('Улучшение работы бонусной <br/> программы Vertera Stage 2')
                    },
                    {
                        title: this.t('Улучшение работы бонусной <br/> программы Vertera Stage 1')
                    },

                ],
            3: [
                {
                    title: this.t('Smart Stacking')
                },
                {
                    title: this.t('Программа <br/> лояльности')
                },
                {
                    title: this.t('Airdrop')
                },
                {
                    title: this.t('Добавление функционала <br/> аналитики Stage 1')
                },
                {
                    title: this.t('Готовое решение <br/> для ритейлеров')
                },
            ],
            4:
                [
                    {
                        title: this.t('Разработка плагина <br/> для браузера')
                    },
                    {
                        title: this.t('Интеграция <br/> с WR market Stage 2')
                    },
                    {
                        title: this.t('Интеграция <br/> с WR market Stage 1')
                    },
                    {
                        title: this.t('Добавление функционала <br/> аналитики Stage 2')
                    }
                ]
        }
    }

    getTemplate = (item, i, x) => {


        let c = (
            <div key={this.uid()}
                 className={'item ' + (x >= 8 ? 'mobile-item-hide' + (this.data.open ? ' show' : '') : '')}>
                <div className={'border-xs-n border-xs-' + x}></div>
                <div className="left-border-container">
                    <div className={'left-border'}></div>
                </div>
                {item.title}
            </div>
        )

        if (x === 8 && !this.data.open) {
            return (
                <div key={this.uid()}>
                    <a className={'color-blue show-all-items pr'} href="/"
                       onClick={(e) => {
                           e.preventDefault()
                           this.set({open: !this.data.open})
                       }}>
                        {this.t('показать все')}
                    </a>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            )
        }

        return c
    }

    parseItems = (list, items = []) => {

        let groups = {
            1: [],
            2: [],
            3: [],
            4: [],
        }
        let x = 0;
        Object.entries(list).map((groupEntry, gi) => {
            let groupNr = gi + 1
            return Object.entries(groupEntry[1]).map((entry, i) => {
                x = x + 1;
                return groups[groupNr].push(this.getTemplate(entry[1], groupNr, x))
            })
        })

        Object.keys(groups).map((k) => {
            return items.push(
                <Row key={this.uid()} className={'item-group item-group' + k}>
                    <Col className={' plan-items d-flex align-items-center d-flex justify-content-around'}>
                        {groups[k]}
                    </Col>
                </Row>
            )
        })

        return items
    }

    render() {
        // d-flex align-items-center justify-content-around

        let items = []

        let itemList = this.getItems()

        items = this.parseItems(itemList, items)

        return (
            <div className={'plans pr'}>
                <h1 className={'header'}>
                    {this.t('Наши планы <br/> по развитию проекта')}
                </h1>
                <br/>
                <Row>
                    <Col className={'title-info ttuc light fsMd'}>
                        <div className="bubble_lg_right red2"></div>
                        {
                            this.t('{vertera} разрабатывает единственную универсальную <br/> систему бонусных баллов на базе Blockchain.', {
                                vertera: <span className={'color-blue normal'}>WR Token –</span>
                            })
                        }
                    </Col>
                </Row>


                <div className={'items-container'}>
                    <div className={'items-title'}>
                        <div className={'border-xs'}></div>
                        {this.t('WR Token <br/> Product Roadmap')}
                    </div>
                    <div className={'items '}>
                        {items}
                    </div>
                </div>
            </div>
        )
    }

}

export default Plans;