import Block from "core/Block";

const Content = class Content {

    rows = (items = [],params = {}) => {

        let i = []

        items.map((k, n) => {
            return i.push(<Block key={n} content={k} sizes={params.sizes}/>)
        })

        return (
            <div className="row" {...params}>
                {i}
            </div>
        )
    }
}

export default new Content()