import {Component} from "react";

/**
 * block component
 */
class Block extends Component {

    data = {
        class: 'col',
        els: (this.props.els ? this.props.els : [1, 2, 3, 4]),
        sizes: {
            xs: 6,
            sm: 4,
            md: 3,
            lg: 3,
        }
    }

    render() {

        if (this.content)
            return this.content

        let cl = this.data.class
        let sizes = this.data.sizes

        if (this.props.className)
            cl = cl + ' ' + this.props.className

        if (this.props.sizes)
            Object.keys(sizes).map((k, i) => sizes[k] = this.props.sizes[i])

        if (sizes)
            Object.keys(sizes).map((key) => {
                cl = cl + ' col-' + key + '-' + sizes[key]
                return ''
            })

        this.content = (
            <div className={cl}>
                {this.props.content}
            </div>
        )

        return this.content
    }
}

export default Block;