import {Link} from "react-router-dom";

const Route = class Route {


    urls = {
        login: '/login',
        logout: '/quit',
        profile: '/profile',
        item: '/item',
        home: '/',
        advantages: '/advantages',
        audience: '/audience',
        devPlan: '/dev-plan',
        contacts: '/contacts',
    }

    component = null
    history = null
    location = null
    module = 'site'
    returnTo = this.urls.home

    /**
     * @param r
     * @param component
     */
    setHistory = (r, component) => {
        this.component = component
        if (!this.history) {
            this.history = r.history
            this.history.listen((location, action) => {
                this.location = location
                this.onRouteChange();
            });
            this.location = this.history.location
        }
        this.setModule()
        setTimeout(() => {
            this.goToElement(this.module)
        }, 1000)
    }

    /**
     * trigger callback on route change
     */
    onRouteChange = () => {
        this.setModule()
        this.component.set({
            routeChanged: this.location.key + new Date().getUTCMilliseconds() + this.component.uid()
        })
        this.goToElement(this.module)
    }

    goToElement = (id) => {
        let element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: "smooth"
            });
        }
    }

    /**
     * set app module based on path value
     */
    setModule = () => {

        let pn = this.location.pathname;
        pn = pn.split('/')

        this.module = pn[1] ? pn[1] : 'site';
    }

    /**
     * create link with content
     * @param content string|ReactObject
     * @param url string
     * @param params object
     * @return {JSX.Element}
     */
    link = (content, url, params = {}) => {
        let activeLink = false
        let currentUrl = this.location.pathname + this.location.search;
        if (url === currentUrl) {
            activeLink = true
        }

        if (activeLink) {
            if (params.className) {
                params.className = params.className + ' active-link'
            } else {
                params.className = 'active-link'
            }
        }

        let btnParams = params.btnProps ? params.btnProps : {
            className: 'cp w100'
        };

        delete params.btnProps

        if (url.startsWith('http'))
            return <a tabIndex={-1} href={url} {...params}>
                <button {...btnParams}>
                    {content}
                </button>
            </a>

        return <Link tabIndex={-1} to={url} {...params}>
            <button {...btnParams}>
                {content}
            </button>
        </Link>
    }

    /**
     * redirect
     * @param route
     */
    redirect = (route) => {
        this.history.push(route)
        this.onRouteChange()
    }
}

export default new Route()