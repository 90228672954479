import {
    Component
} from "main/Imports";

class Market extends Component {

    data = {}


    render() {
        let c = (
            <div className={'market'}>
                <div className="bubble_lg_left red"></div>
                <h1 className={'header tal'}>
                    {this.t('Аудитория <br/> и объемы рынка')}
                </h1>
                <p className={'light fsSm'}>
                    {
                        this.t('Целевые группы, с которыми <br/> мы сейчас ведем работу')
                    }
                </p>

                <br/>
                <br/>

                <div className="boxes">
                    <div className="box box-1">
                        <h2 className={'fsMd'}>
                            {this.t('Аудитория Vertera –<br/>400 000 человек')}
                        </h2>
                        <br/>
                        <div className="desc light fsSm">
                            {this.t('Тестовые группы уже сейчас работают с Alpha-версией<br/>' +
                                'нашего решения, чтобы вскоре открыть возможность использовать токен в качестве бонуса всем клиентам {vertera_link}.', {
                                vertera_link: this.ready(() => {
                                    return this.__ROUTE.link('Vertera', this.__APP.data.language === 'ru' ? 'https://vertera.org/' : 'https://vertera.eu/', {
                                        className: 'color-blue',
                                        target: '_blank'
                                    })
                                })
                            })}
                        </div>
                    </div>

                    <div className="box box-2">
                        <h2 className={'fsMd'}>
                            {this.t('Мелкие и средние <br/>' +
                                'ритейлеры')}
                        </h2>
                        <br/>
                        <div className="desc light fsSm">
                            {this.t('Разрабатываем для них готовые бонусные программы на базе нашего токена, которые включают программное решение, юридическую базу и онлайн сопровождение. Подключение одного ритейлера будет означать подключение всей базы его покупателей к работе <br/> ' +
                                'с WR Token.')}
                        </div>
                    </div>

                    <div className="box box-3">
                        <h2 className={'fsMd'}>
                            {this.t('Пользователи <br/> биржи Whitebit')}
                        </h2>
                        <br/>
                        <div className="desc light fsSm">
                            {this.t('Один из крупнейших crypto <br/> ' +
                                'market проектов на территории <br/> СНГ с русскоговорящей <br/> аудиторией, которая расположена к работе <br/> со «своими» проектами. На нем мы уже провели листинг, чтобы дать возможность нашим пользователям быстро покупать и обменивать WR Token в сети.')}
                        </div>
                    </div>


                </div>
            </div>
        )

        return c
    }

}

export default Market;