import {Component} from "main/Imports";
import Home from "main/views/Home";
import {Route} from "react-router-dom";


class MainContent extends Component {

    render() {

        let route = this.__ROUTE

        return <Route path={route.urls.home} exact={false} component={Home}/>
    }
}


export default MainContent;