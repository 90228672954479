import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from "react-router-dom";
import BaseComponent from "core/BaseComponent";
import {CoreProvider} from "core/CoreContext";
import MainApp from "main/MainApp";


let routerParams = {
    forceRefresh: false,
}

class IndexClass extends BaseComponent {


    /**
     * get system language from browser
     * @return {string}
     */
    getBrowserLang = () => {
        let l = [
            'ru', 'en'
        ]
        let lang = navigator.language
        if (!lang)
            return 'ru'

        lang = lang.split('-')

        if (lang[0] && l.includes(lang[0])) {
            lang = lang[0]
        } else {
            lang = 'ru'
        }
        lang = lang.toLowerCase()
        return lang
    }

    data = {
        routerParams: routerParams,
        mounted: null,
        quit: false,
        language: this.get('language', this.getBrowserLang()),
        languageList: {
            ru: {label: 'Русский', value: 'ru'},
            en: {label: 'English', value: 'en'}
        }
    }

    constructor(props) {
        super(props);
        this.__ROUTE.setHistory(this.props.request, this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.data.mounted === false)
            this.set({mounted: true})
    }

    componentDidMount() {
        this.set({mounted: false})
    }

    /**
     * @return {JSX.Element}
     */
    render() {

        let hot = this.__HOT_KEYS;

        let content = (
            <div>
                <CoreProvider value={this}>
                    {
                        hot.render('Escape', (
                            <div>
                                <MainApp/>
                            </div>
                        ), () => {
                            if (document.activeElement) {
                                document.activeElement.blur();
                            }
                            window.focus();
                        }, {}, true)
                    }
                </CoreProvider>
            </div>
        )

        content = this.__HOT_KEYS.render('Shift+S', content, () => {
            console.log(this.__ROUTE.module)
        })

        return this.data.mounted === true ? content : ''

    }
}

ReactDOM.render(
    <React.Fragment>
        <BrowserRouter {...routerParams}>
            <Route path={'/'} render={(r) => {
                return <IndexClass request={r}/>
            }}/>
        </BrowserRouter>
    </React.Fragment>, document.getElementById('root')
);